<template>
  <div>
    <div class="all-height">
      <div>
        <!-- <div class="title grey--text text-center">Select a {{type}}</div> -->
        <div class="text-center" v-if="view === 1 && previousData.length > 0">
          <div class="d-flex flex-wrap justify-center scrollable-table-container" @scroll="handleScroll">
            <div class="" v-for="(v, k) in data" :key="k">
              <v-card class="ma-3 text-left scrollable-card" v-ripple @click="sendEvent('click', v)">
                <v-card-text class="border-on hoverable cursor-pointer">
                  <div class="d-flex align-center">
                    <div>
                      <div class="subtitle-1 font-weight-bold">{{v.name}}</div>
                      <div class="font-weight-bold d-flex align-center">
                        <span class="mr-1 display-1 font-weight-bold grey--text text--darken-1">{{v.rucount}}</span>
                        <span style="line-height:1.3" class="caption">Reporting<br />Units</span>
                      </div>
                    </div>
                    <v-spacer></v-spacer>
                    <div class="ml-4">
                      <!-- <v-progress-circular :size="55" :width="10" color="success"
                        :value="($nova.formatNumber((v.approved_regional || 0)/((v.count || 0) - (v.nodata_regional || 0) - (v.immaterial_regional || 0) - (v.notinscope_regional || 0))*100))">
                        {{ ($nova.formatNumber(((v.approved_regional || 0)/((v.count || 0) - (v.nodata_regional || 0) -
                        (v.immaterial_regional || 0) - (v.notinscope_regional || 0)) *100).toFixed(0))) }}%
                      </v-progress-circular> -->

                      <v-progress-circular :size="55" :width="10" color="success"
                        :value="(Number(v.regional_ru_approve)/Number(v.rucount))*100">
                        {{ ((Number(v.regional_ru_approve)/Number(v.rucount))*100).toFixed(0) }}%
                      </v-progress-circular>
                    </div>
                  </div>
                  <div class="d-flex mt-2">
                    <v-spacer></v-spacer>
                    <v-tooltip bottom content-class="tooltip-bottom">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="mx-3 d-flex align-center">
                          <v-icon color="success">mdi-check-circle</v-icon>
                          <span class="body-1 ml-1">{{v.local_ru_approve}}</span>
                        </div>
                      </template>
                      <span>RU Approved at Locally</span>
                    </v-tooltip>
                    <!-- <v-tooltip bottom content-class="tooltip-bottom">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="mx-3 d-flex align-center">
                          <v-icon>mdi-circle-off-outline</v-icon>
                          <span class="body-1 ml-1">{{v.cyclefreez}}</span>
                        </div>
                      </template>
                      <span>Freeze</span>
                    </v-tooltip> -->
                    <v-tooltip bottom content-class="tooltip-bottom">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="mx-3 d-flex align-center">
                          <v-icon color="success">mdi-check-circle-outline</v-icon>
                          <span class="body-1 ml-1">{{v.regional_ru_approve}}</span>
                        </div>
                      </template>
                      <span>Regional RU Approval Done</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </div>
        </div>
        <div class="" v-else-if="(view === 0 || view === 2) ">
          <div class="d-flex mb-1" v-if="pagecount > 1">
            <v-spacer />
            <div class="radius-on border-on" v-if="view === 2">
              <v-btn icon small :disabled="page === 1" @click="page--">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <span class="mx-1">Page {{page}} of {{pagecount}}</span>
              <v-btn icon small :disabled="page === pagecount" @click="page++">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </div>
          <!-- <div class="radius-small">
            <v-simple-table dense fixed-header :height="tableHeight" class="v-table-color" style="max-height: 766px;"> -->
          <div class="scrollable-table-container radius-small" @scroll="handleScroll">
            <v-simple-table dense fixed-header class="v-table-color">
              <template>  
              <thead>
                <tr>
                  <th scope="slno" class="text-center">#</th>
                  <th scope="selectbox" v-if="enablecheckboxlocal || enablecheckboxregional" class="text-center"></th>
                  <th scope="ruapproved"></th>
                  <th scope="">
                    <div :class="`${(type === 'Reporting Unit') ? 'text-center' : 'text-left'}`"></div>
                  </th>
                  <th scope="" v-if="type === 'Reporting Unit'"></th>
                  <th scope="type" style="min-width:250px">{{type}}
                    <v-menu v-if="type === 'Reporting Unit'" max-width="300" offset-y :close-on-content-click="false"
                      key="_id" rounded="lg">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon x-small v-bind="attrs" v-on="on" @click="getFilterRUs();setHeader('rucode')">
                          <v-icon x-small v-if="type === 'Reporting Unit'" class="grey--text text--lighten-1">
                            mdi-filter
                          </v-icon>
                          <v-icon x-small v-else>mdi-filter-menu </v-icon>
                        </v-btn>
                      </template>
                      <v-card max-height="400">
                        <v-card-text class="pa-0">
                          <lb-string class="px-2 pt-2 mt-0" label="Search" v-model="seachFilter" hidedetails />
                        </v-card-text>
                        <div class="mt-2 ml-3 d-flex" v-if="selectedFilterItems.length > 0">
                          {{ selectedFilterItems.length || "" }} Selected
                          Items
                          <v-spacer></v-spacer>
                          <div class="mr-8">
                            <v-btn class="" x-small @click="removeFilteredItems()">
                              Clear Selections
                            </v-btn>
                          </div>
                        </div>
                        <v-tabs v-model="tab" centered height="30" class="mt-2">
                          <v-tabs-slider></v-tabs-slider>
                          <v-tab href="#tab-1" class="text-transform-none justify-start">
                            <v-icon class="pa-1"> mdi-checkbox-blank-outline</v-icon>
                            Unselected
                          </v-tab>
                          <v-tab href="#tab-2" class="text-transform-none justify-start">
                            <v-icon class="pa-1"> mdi-checkbox-marked</v-icon>
                            Selected
                          </v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tab">
                          <v-tab-item value="tab-1">
                            <v-card-text class="pa-0 ma-0 pt-2 scroll">
                              <v-checkbox v-for="(option, k) in getFilterRUList('rucode', seachFilter).slice(0,25)"
                                :key="k" class="pa-2 mt-0 pt-0" dense multiple scrollable :label="option.code"
                                :value="option._id" hide-details v-model="filterValue" />
                              <div v-if="getFilterRUList('rucode', seachFilter).length > 25"
                                class="mx-2 my-1 grey--text caption">{{
                                getFilterRUList('rucode', seachFilter).length - 25 }} more...</div>
                            </v-card-text>
                          </v-tab-item>
                          <v-tab-item value="tab-2">
                            <v-card-text class="pa-0 ma-0 pt-2 scroll">
                              <div v-for="(item, k) in selectedFilterItems" :key="k">
                                <v-checkbox :value="item" class="pa-2 mt-0 pt-0" dense multiple
                                  :label="item?.code || 'No code'" v-model="selectedFilterItems" hide-details
                                  scrollable @click="removeSelectedFilterItem(item)">
                                </v-checkbox>
                              </div>
                            </v-card-text>
                          </v-tab-item>
                        </v-tabs-items>
                        <v-btn width="100%" v-if="selectedFilterItems.length >0" @click="getaccessFilterRUlist()">
                          <span class="bold mr-2">Get</span>
                          <v-icon>mdi mdi-launch</v-icon>
                        </v-btn>
                      </v-card>
                    </v-menu>
                  </th>
                  <th scope=""></th>
                  <th scope="pending" class="text-center">Commentary awaited</th>
                  <th scope="pendingapproval" class="text-center">Commentary awaiting approval</th>
                  <th scope="approved" class="text-center">Approved</th>
                  <th scope="rejected" class="text-center">Rejected</th>
                  <th scope="immaterial" class="text-center">Immaterial</th>
                  <th scope="submittercontrolprogress" class="text-center">Local Preparer Control Progress</th>
                  <th scope="approvercontrolprogress" class="text-center">Local Approver Control Progress</th>
                  <th scope="submittermanagementprogress" class="text-center">Local Preparer Management Progress</th>
                  <th scope="approvermanagementprogress" class="text-center">Local Approver Management Progress</th>
                  <th scope="action" class="text-center" v-if="type==='Reporting Unit'">Action</th>
                </tr>
              </thead>
              <tbody >
              <template>   
                <tr @click="sendEvent('click', v)" class="hoverable cursor-pointer" v-for="(v,k) in filtereddata"
                  :key="k">
                  <td class="text-center">
                    <div class="font-weight-bold mx-1">
                      {{((page-1)*perpage) + k+1}}.
                    </div>
                  </td>
                  <td v-if="(enablecheckboxlocal) || (enablecheckboxregional)" @click.stop>
                    <v-checkbox :value="v.cycle" v-model="selectedlocalapproval" multiple
                      v-if="(v.cycle_detail?.local_ru_approve !== true) && ((v.control_pending_local_proforma + v.control_pendingapproval_local_proforma + v.control_rejected_local_proforma) === 0)&& ((v.control_pendingapproval_local_forecast + v.control_pendingapproval_local_forecast + v.control_rejected_local_forecast) === 0) && (v.reportingunit_detail?.approver || []).indexOf(userid) > -1 && (v.control_approved_local_proforma === v.control_count_local_proforma)&& (v.control_approved_local_forecast === v.control_count_local_forecast) && (v.control_workflow_disabled_local_proforma===false || v.control_workflow_disabled_local_forecast===false || v.management_workflow_disabled_local_proforma===false || v.management_workflow_disabled_local_forecast===false)"
                      hide-details class="pa-0 ma-0" dense></v-checkbox>
                    <v-checkbox :value="v.cycle" v-model="selectedregionalapproval" multiple
                      v-else-if="(v.cycle_detail?.regional_ru_approve !== true) && ((v.control_pending_regional_proforma + v.control_pendingapproval_regional_proforma + v.control_rejected_regional_proforma) === 0) && ((v.control_pending_regional_forecast + v.control_pendingapproval_regional_forecast + v.control_rejected_regional_forecast) === 0) && (v.reportingunit_detail?.regional_approver || []).indexOf(userid) > -1 && (v.control_approved_regional_proforma === v.control_count_regional_proforma)&&  (v.control_approved_regional_forecast === v.control_count_regional_forecast) && (v.control_workflow_disabled_regional_proforma===false ||v.control_workflow_disabled_regional_forecast===false || v.management_workflow_disabled_regional_proforma===false || v.management_workflow_disabled_regional_forecast===false)"
                      hide-details class="pa-0 ma-0" dense></v-checkbox>
                  </td>
                  <td>
                    <div class="d-flex flex-column">
                      <v-tooltip bottom content-class="tooltip-bottom">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <v-icon v-if="v.control_workflow_disabled_local_proforma && v.control_workflow_disabled_local_forecast" color="grey">mdi-close-circle</v-icon>
                            <v-icon v-else-if="v.cycle_detail?.local_ru_approve" color="success">mdi-check-circle</v-icon>
                            <v-icon v-else>mdi-timer-sand-full</v-icon>
                          </div>
                        </template>
                        <span v-if="v.control_workflow_disabled_local_proforma && v.control_workflow_disabled_local_forecast" >Local RU Level Approval Disabled</span>
                        <span v-else-if="v.cycle_detail?.local_ru_approve">Local RU Approval Done</span>
                        <span v-else>Local RU Approval Pending</span>
                      </v-tooltip>
                      <v-tooltip bottom content-class="tooltip-bottom">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <v-icon v-if="v.control_workflow_disabled_regional_proforma && v.control_workflow_disabled_regional_forecast" color="grey"> mdi-close-circle-outline</v-icon>
                            <v-icon v-else-if="v.cycle_detail?.regional_ru_approve"
                              color="success">mdi-check-circle-outline</v-icon>
                            <v-icon v-else>mdi-timer-sand</v-icon>
                          </div>
                        </template>
                        <span v-if="v.control_workflow_disabled_regional_proforma && v.control_workflow_disabled_regional_forecast">Regional RU Level Approval Disabled</span>
                        <span v-else-if="v.cycle_detail?.regional_ru_approve">Regional RU Approval Done</span>
                        <span v-else>Regional RU Approval Pending</span>
                      </v-tooltip>
                    </div>
                  </td>
                  <td class="">
                    <!-- <div>
                      <v-tooltip bottom content-class="tooltip-bottom"
                        v-if="(v.cycleopen > 0 && type === 'Reporting Unit') || type !== 'Reporting Unit'">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on"
                            :class="`d-flex align-center ${(type === 'Reporting Unit')?'justify-center':''}`">
                            <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
                            <span class="ml-1" v-if="type !== 'Reporting Unit'">{{v.cycleopen}}</span>
                          </div>
                        </template>
                        <span>Open</span>
                      </v-tooltip>
                    </div>
                    <div>
                      <v-tooltip bottom content-class="tooltip-bottom"
                        v-if="(v.cycleclose > 0 && type === 'Reporting Unit') || type !== 'Reporting Unit'">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on"
                            :class="`d-flex align-center ${(type === 'Reporting Unit')?'justify-center':''}`">
                            <v-icon>mdi-checkbox-blank-circle</v-icon>
                            <span class="ml-1" v-if="type !== 'Reporting Unit'">{{v.cycleclose}}</span>
                          </div>
                        </template>
                        <span>Closed</span>
                      </v-tooltip>
                    </div> -->
                  </td>
                  <td class="" v-if="type === 'Reporting Unit'">
                    <div>
                      <v-tooltip bottom content-class="tooltip-bottom"
                        v-if="(v.reportingunit_detail?.submitter || []).indexOf(userid) > -1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip v-bind="attrs" v-on="on" small class="px-1" label color="info">
                          </v-chip>
                        </template>
                        <span>You are local preparer</span>
                      </v-tooltip>
                      <v-tooltip bottom content-class="tooltip-bottom"
                        v-else-if="(v.reportingunit_detail?.approver || []).indexOf(userid) > -1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip v-bind="attrs" v-on="on" small class="px-1" label color="warning">
                          </v-chip>
                        </template>
                        <span>You are local approver</span>
                      </v-tooltip>
                      <v-tooltip bottom content-class="tooltip-bottom"
                        v-else-if="(v.reportingunit_detail?.regional_approver || []).indexOf(userid) > -1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip v-bind="attrs" v-on="on" small class="px-1" label color="success">
                          </v-chip>
                        </template>
                        <span>You are regional approver</span>
                      </v-tooltip>
                      <v-tooltip bottom content-class="tooltip-bottom"
                        v-else-if="(v.reportingunit_detail?.manager || []).indexOf(userid) > -1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip v-bind="attrs" v-on="on" small class="px-1" label color="grey">
                          </v-chip>
                        </template>
                        <span>You are manager</span>
                      </v-tooltip>
                      <v-tooltip bottom content-class="tooltip-bottom" v-else>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip v-bind="attrs" v-on="on" small class="px-1" label color="error">
                          </v-chip>
                        </template>
                        <span>You have view only access</span>
                      </v-tooltip>
                    </div>
                  </td>
                  <td class="py-1">
                    <div>
                      <div class="font-weight-bold">{{v.reportingunit_detail?.code}} - {{v.name}}</div>
                      <div class="grey--text text--darken-2" v-if="type !== 'Reporting Unit'">
                        <span class="mr-1">{{v.rucount || 0}}</span>
                        <span>Reporting Units</span>
                      </div>
                    </div>
                  </td>
                  <td class="text-left ellipsis-on">
                    <div>Local Proforma</div>
                    <div>Local Forecast</div>
                    <div>Regional Proforma</div>
                    <div>Regional Forecast</div>
                  </td>
                  <td class="info--text text-center">
                    <div :id="v.name+'pending_local_proforma'">{{v.pending_local_proforma || 0}}</div>
                    <div :id="v.name+'pending_local_forecast'">{{v.pending_local_forecast || 0}}</div>
                    <div :id="v.name+'pending_regional_proforma'">{{v.pending_regional_proforma || 0}}</div>
                    <div :id="v.name+'pending_regional_forecast'">{{v.pending_regional_forecast || 0}}</div>
                  </td>
                  <td class="warning--text text-center">
                    <div :id="v.name+'pendingapproval_local_proforma'">{{v.pendingapproval_local_proforma || 0}}</div>
                    <div :id="v.name+'pendingapproval_local_forecast'">{{v.pendingapproval_local_forecast || 0}}</div>
                    <div :id="v.name+'pendingapproval_regional_proforma'">{{v.pendingapproval_regional_proforma || 0}}</div>
                    <div :id="v.name+'pendingapproval_regional_forecast'">{{v.pendingapproval_regional_forecast || 0}}</div>
                  </td>
                  <td class="success--text text-center">
                    <div :id="v.name+'approved_local_proforma'">{{v.approved_local_proforma || 0}}</div>
                    <div :id="v.name+'approved_local_forecast'">{{v.approved_local_forecast || 0}}</div>
                    <div :id="v.name+'approved_regional_proforma'">{{v.approved_regional_proforma || 0}}</div>
                    <div :id="v.name+'approved_regional_forecast'">{{v.approved_regional_forecast || 0}}</div>
                  </td>
                  <td class="error--text text-center">
                    <div :id="v.name+'rejected_local_proforma'">{{v.rejected_local_proforma || 0}}</div>
                    <div :id="v.name+'rejected_local_forecast'">{{v.rejected_local_forecast || 0}}</div>
                    <div :id="v.name+'rejected_regional_proforma'">{{v.rejected_regional_proforma || 0}}</div>
                    <div :id="v.name+'rejected_regional_forecast'">{{v.rejected_regional_forecast || 0}}</div>
                  </td>
                  <td class="text-center">
                    <div :id="v.name+'immaterial_local_proforma'">{{v.immaterial_local_proforma || 0}}</div>
                    <div :id="v.name+'immaterial_local_forecast'">{{v.immaterial_local_forecast || 0}}</div>
                    <div :id="v.name+'immaterial_regional_proforma'">{{v.immaterial_regional_proforma || 0}}</div>
                    <div :id="v.name+'immaterial_regional_forecast'">{{v.immaterial_regional_forecast || 0}}</div>
                  </td>
                  <td class="py-1 text-center">
                    <div class="my-1"  >
                      <div v-if="v.control_workflow_disabled_local_proforma" :id="v.name+'control_local_awaiting_commentary_proforma_progress'">
                        Disabled
                      </div>
                      <div v-else class="mb-1 d-flex align-center justify-center" :id="v.name+'control_local_awaiting_commentary_proforma_progress'">
                        <div style="min-width: 60px !important; max-width: 70px !important;">
                          <v-progress-linear :height="16" width="30" color="info"
                            :value="($nova.formatNumber(getPercentageProgress((v.control_pendingapproval_local_proforma || 0) + (v.control_approved_local_proforma || 0), (v.control_count_local_proforma || 0))*100))">
                            <span
                              class="line-1">{{$nova.formatNumber((getPercentageProgress((v.control_pendingapproval_local_proforma
                              || 0) + (v.control_approved_local_proforma || 0), (v.control_count_local_proforma ||
                              0))*100).toFixed(0))}}%</span>
                          </v-progress-linear>
                        </div>
                      </div>
                      <div v-if="v.control_workflow_disabled_local_forecast" :id="v.name+'control_local_awaiting_commentary_forecast_progress'">
                        Disabled
                      </div>
                      <div v-else class="mb-1 d-flex align-center justify-center" :id="v.name+'control_local_awaiting_commentary_forecast_progress'">
                        <div style="min-width: 60px !important; max-width: 70px !important;">
                          <v-progress-linear :height="16" width="30" color="info"
                            :value="($nova.formatNumber(getPercentageProgress((v.control_pendingapproval_local_forecast || 0) + (v.control_approved_local_forecast || 0), (v.control_count_local_forecast || 0))*100))">
                            <span
                              class="line-1">{{$nova.formatNumber((getPercentageProgress((v.control_pendingapproval_local_forecast
                              || 0) + (v.control_approved_local_forecast || 0), (v.control_count_local_forecast ||
                              0))*100).toFixed(0))}}%</span>
                          </v-progress-linear>
                        </div>
                      </div>
                      <div v-if="v.control_workflow_disabled_regional_proforma" :id="v.name+'control_regional_awaiting_commentary_proforma_progress'">
                        Disabled
                      </div>
                      <div v-else class="d-flex align-center justify-center" :id="v.name+'control_regional_awaiting_commentary_proforma_progress'">
                        <div style="min-width: 60px !important; max-width: 70px !important;">
                          <v-progress-linear :height="16" width="30" color="info"
                            :value="($nova.formatNumber(getPercentageProgress((v.control_pendingapproval_regional_proforma || 0) + (v.control_approved_regional_proforma || 0), (v.control_count_regional_proforma || 0))*100))">
                            <span
                              class="line-1">{{$nova.formatNumber((getPercentageProgress((v.control_pendingapproval_regional_proforma
                              || 0) + (v.control_approved_regional_proforma || 0), (v.control_count_regional_proforma ||
                              0))*100).toFixed(0))}}%</span>
                          </v-progress-linear>
                        </div>
                      </div>
                      <div v-if="v.control_workflow_disabled_regional_forecast" :id="v.name+'control_regional_awaiting_commentary_forecast_progress'">
                        Disabled
                      </div>
                      <div v-else class="mb-1 d-flex align-center justify-center" :id="v.name+'control_regional_awaiting_commentary_forecast_progress'">
                        <div style="min-width: 60px !important; max-width: 70px !important;">
                          <v-progress-linear :height="16" width="30" color="info"
                            :value="($nova.formatNumber(getPercentageProgress((v.control_pendingapproval_regional_forecast || 0) + (v.control_approved_regional_forecast || 0), (v.control_count_regional_forecast || 0))*100))">
                            <span
                              class="line-1">{{$nova.formatNumber((getPercentageProgress((v.control_pendingapproval_regional_forecast
                              || 0) + (v.control_approved_regional_forecast || 0), (v.control_count_regional_forecast ||
                              0))*100).toFixed(0))}}%</span>
                          </v-progress-linear>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="py-1 text-center">
                    <div class="my-1">
                      <div v-if="v.control_workflow_disabled_local_proforma" :id="v.name+'control_local_awaiting_approval_proforma_progress'">
                        Disabled
                      </div>
                      <div v-else class="mb-1 d-flex align-center justify-center" :id="v.name+'control_local_awaiting_approval_proforma_progress'">
                        <div style="min-width: 60px !important; max-width: 70px !important;">
                          <v-progress-linear :height="16" width="30" color="info"
                            :value="($nova.formatNumber(getPercentageProgress((v.control_approved_local_proforma || 0), (v.control_count_local_proforma || 0))*100))">
                            <span
                              class="line-1">{{$nova.formatNumber((getPercentageProgress((v.control_approved_local_proforma || 0), (v.control_count_local_proforma ||
                              0))*100).toFixed(0))}}%</span>
                          </v-progress-linear>
                        </div>
                      </div>
                      <div class="my-1">
                      <div v-if="v.control_workflow_disabled_local_forecast" :id="v.name+'control_local_awaiting_approval_forecast_progress'">
                        Disabled
                      </div>
                      <div v-else class="mb-1 d-flex align-center justify-center" :id="v.name+'control_local_awaiting_approval_forecast_progress'">
                        <div style="min-width: 60px !important; max-width: 70px !important;">
                          <v-progress-linear :height="16" width="30" color="info"
                            :value="($nova.formatNumber(getPercentageProgress((v.control_approved_local_forecast || 0) , (v.control_count_local_forecast || 0))*100))">
                            <span
                              class="line-1">{{$nova.formatNumber((getPercentageProgress((v.control_approved_local_forecast || 0), (v.control_count_local_forecast ||
                              0))*100).toFixed(0))}}%</span>
                          </v-progress-linear>
                        </div>
                      </div>
                      <div v-if="v.control_workflow_disabled_regional_proforma" :id="v.name+'control_regional_awaiting_approval_proforma_progress'">
                        Disabled
                      </div>
                      <div v-else class="d-flex align-center justify-center" :id="v.name+'control_regional_awaiting_approval_proforma_progress'">
                        <div style="min-width: 60px !important; max-width: 70px !important;">
                          <v-progress-linear :height="16" width="30" color="info"
                            :value="($nova.formatNumber(getPercentageProgress((v.control_approved_regional_proforma || 0), (v.control_count_regional_proforma || 0))*100))">
                            <span
                              class="line-1">{{$nova.formatNumber((getPercentageProgress((v.control_approved_regional_proforma || 0), (v.control_count_regional_proforma ||
                              0))*100).toFixed(0))}}%</span>
                          </v-progress-linear>
                        </div>
                      </div>
                      <div v-if="v.control_workflow_disabled_regional_forecast" :id="v.name+'control_regional_awaiting_approval_forecast_progress'">
                        Disabled
                      </div>
                      <div v-else class="mb-1 d-flex align-center justify-center" :id="v.name+'control_regional_awaiting_approval_forecast_progress'">
                        <div style="min-width: 60px !important; max-width: 70px !important;">
                          <v-progress-linear :height="16" width="30" color="info"
                            :value="($nova.formatNumber(getPercentageProgress((v.control_approved_regional_forecast || 0), (v.control_count_regional_forecast || 0))*100))">
                            <span
                              class="line-1">{{$nova.formatNumber((getPercentageProgress((v.control_approved_regional_forecast || 0), (v.control_count_regional_forecast ||
                              0))*100).toFixed(0))}}%</span>
                          </v-progress-linear>
                        </div>
                      </div>
                      </div>
                    </div>
                  </td>
                  <td class="py-1 text-center">
                    <div class="my-1">
                      <div v-if="v.management_workflow_disabled_local_proforma" :id="v.name+'management_local_awaiting_commentary_proforma_progress'">
                        Disabled
                      </div>
                      <div v-else class="mb-1 d-flex align-center justify-center" :id="v.name+'management_local_awaiting_commentary_proforma_progress'">
                        <div style="min-width: 60px !important; max-width: 70px !important;">
                          <v-progress-linear :height="16" width="30" color="info"
                            :value="($nova.formatNumber(getPercentageProgress((v.management_pendingapproval_local_proforma || 0) + (v.management_approved_local_proforma || 0), (v.management_count_local_proforma || 0))*100))">
                            <span
                              class="line-1">{{$nova.formatNumber((getPercentageProgress((v.management_pendingapproval_local_proforma
                              || 0) + (v.management_approved_local_proforma || 0), (v.management_count_local_proforma ||
                              0))*100).toFixed(0))}}%</span>
                          </v-progress-linear>
                        </div>
                      </div>
                      <div v-if="v.management_workflow_disabled_local_forecast" :id="v.name+'management_local_awaiting_commentary_forecast_progress'">
                        Disabled
                      </div>
                      <div v-else class="mb-1 d-flex align-center justify-center" :id="v.name+'management_local_awaiting_commentary_forecast_progress'">
                        <div style="min-width: 60px !important; max-width: 70px !important;">
                          <v-progress-linear :height="16" width="30" color="info"
                            :value="($nova.formatNumber(getPercentageProgress((v.management_pendingapproval_local_forecast || 0) + (v.management_approved_local_forecast || 0), (v.management_count_local_forecast || 0))*100))">
                            <span
                              class="line-1">{{$nova.formatNumber((getPercentageProgress((v.management_pendingapproval_local_forecast
                              || 0) + (v.management_approved_local_forecast || 0), (v.management_count_local_forecast ||
                              0))*100).toFixed(0))}}%</span>
                          </v-progress-linear>
                        </div>
                      </div>
                      <div v-if="v.management_workflow_disabled_regional_proforma" :id="v.name+'management_regional_awaiting_commentary_proforma_progress'">
                        Disabled
                      </div>
                      <div v-else class="d-flex align-center justify-center" :id="v.name+'management_regional_awaiting_commentary_proforma_progress'">
                        <div style="min-width: 60px !important; max-width: 70px !important;">
                          <v-progress-linear :height="16" width="30" color="info"
                            :value="($nova.formatNumber(getPercentageProgress((v.management_pendingapproval_regional_proforma || 0) + (v.management_approved_regional_proforma || 0), (v.management_count_regional_proforma || 0))*100))">
                            <span
                              class="line-1">{{$nova.formatNumber((getPercentageProgress((v.management_pendingapproval_regional_proforma
                              || 0) + (v.management_approved_regional_proforma || 0), (v.management_count_regional_proforma ||
                              0))*100).toFixed(0))}}%</span>
                          </v-progress-linear>
                        </div>
                      </div>
                      <div v-if="v.management_workflow_disabled_regional_forecast" :id="v.name+'management_regional_awaiting_commentary_forecast_progress'">
                        Disabled
                      </div>
                      <div v-else class="d-flex align-center justify-center" :id="v.name+'management_local_awaiting_commentary_forecast_progress'">
                        <div style="min-width: 60px !important; max-width: 70px !important;">
                          <v-progress-linear :height="16" width="30" color="info"
                            :value="($nova.formatNumber(getPercentageProgress((v.management_pendingapproval_regional_forecast || 0) + (v.management_approved_regional_forecast || 0), (v.management_count_regional_forecast || 0))*100))">
                            <span
                              class="line-1">{{$nova.formatNumber((getPercentageProgress((v.management_pendingapproval_regional_forecast
                              || 0) + (v.management_approved_regional_forecast || 0), (v.management_count_regional_forecast ||
                              0))*100).toFixed(0))}}%</span>
                          </v-progress-linear>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="py-1 text-center">
                    <div class="my-1">
                      <div v-if="v.management_workflow_disabled_local_proforma" :id="v.name+'management_local_awaiting_approval_proforma_progress'">
                        Disabled
                      </div>
                      <div v-else class="mb-1 d-flex align-center justify-center" :id="v.name+'management_local_awaiting_approval_proforma_progress'">
                        <div style="min-width: 60px !important; max-width: 70px !important;">
                          <v-progress-linear :height="16" width="30" color="info"
                            :value="($nova.formatNumber(getPercentageProgress((v.management_approved_local_proforma || 0), (v.management_count_local_proforma || 0))*100))">
                            <span
                              class="line-1">{{$nova.formatNumber((getPercentageProgress((v.management_approved_local_proforma || 0), (v.management_count_local_proforma ||
                              0))*100).toFixed(0))}}%</span>
                          </v-progress-linear>
                        </div>
                      </div>
                      <div v-if="v.management_workflow_disabled_local_forecast" :id="v.name+'management_local_awaiting_approval_forecast_progress'">
                        Disabled
                      </div>
                      <div v-else class="mb-1 d-flex align-center justify-center" :id="v.name+'management_local_awaiting_approval_forecast_progress'">
                        <div style="min-width: 60px !important; max-width: 70px !important;">
                          <v-progress-linear :height="16" width="30" color="info"
                            :value="($nova.formatNumber(getPercentageProgress((v.management_approved_local_forecast || 0), (v.management_count_local_forecast || 0))*100))">
                            <span
                              class="line-1">{{$nova.formatNumber((getPercentageProgress((v.management_approved_local_forecast || 0), (v.management_count_local_forecast ||
                              0))*100).toFixed(0))}}%</span>
                          </v-progress-linear>
                        </div>
                      </div>
                      <div v-if="v.management_workflow_disabled_regional_proforma" :id="v.name+'management_regional_awaiting_approval_proforma_progress'">
                        Disabled
                      </div>
                      <div v-else class="d-flex align-center justify-center" :id="v.name+'management_regional_awaiting_approval_proforma_progress'">
                        <div style="min-width: 60px !important; max-width: 70px !important;">
                          <v-progress-linear :height="16" width="30" color="info"
                            :value="($nova.formatNumber(getPercentageProgress((v.management_approved_regional_proforma || 0), (v.management_count_regional_proforma || 0))*100))">
                            <span
                              class="line-1">{{$nova.formatNumber((getPercentageProgress((v.management_approved_regional_proforma || 0), (v.management_count_regional_proforma ||
                              0))*100).toFixed(0))}}%</span>
                          </v-progress-linear>
                        </div>
                      </div>
                      <div v-if="v.management_workflow_disabled_regional_forecast" :id="v.name+'management_regional_awaiting_approval_forecast_progress'">
                        Disabled
                      </div>
                      <div v-else class="d-flex align-center justify-center" :id="v.name+'management_regional_awaiting_approval_forecast_progress'">
                        <div style="min-width: 60px !important; max-width: 70px !important;">
                          <v-progress-linear :height="16" width="30" color="info"
                            :value="($nova.formatNumber(getPercentageProgress((v.management_approved_regional_forecast || 0), (v.management_count_regional_forecast || 0))*100))">
                            <span
                              class="line-1">{{$nova.formatNumber((getPercentageProgress((v.management_approved_regional_forecast || 0), (v.management_count_regional_forecast ||
                              0))*100).toFixed(0))}}%</span>
                          </v-progress-linear>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td @click.stop class="py-1 text-center" v-if="type==='Reporting Unit'">
                    <div :id="v.name+'activity'">
                      <!-- <v-icon @click=getApproveRUData(v.cycle) class="hoverable cursor-pointer"> mdi-eye</v-icon> -->
                      <v-tooltip bottom content-class="tooltip-bottom">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon @click=getApproveRUData(v.cycle) class="hoverable cursor-pointer" v-bind="attrs" v-on="on"> mdi-eye</v-icon>
                        </template>
                        <span>View details</span>
                      </v-tooltip>
                      <lb-activitylog :config="activitylogconfigcycle"
                        :url="`/v2/varianceanalysis/analysis/getlogs/${v.cycle}/f/${cycletype}`"
                        contentClass="tooltip-bottom-right" />
                    </div>
                  </td>
                </tr>
                <tr v-if="loading && previousData.length===0" class="pa-6 text-center title grey--text">
                  <td colspan="100%" rowspan=3>
                    <span>Please wait as we load your data</span>
                  </td>
                </tr>
                <tr v-else-if="!loading && previousData.length===0" class="pa-6 text-center title grey--text">
                  <td colspan="100%">
                    <span>No Data</span>
                  </td>
                </tr>
                <tr v-else-if="dataLoading">
                  <td colspan="100%">
                    <div class="spinner-container">
                      <div class="loading-spinner"></div>
                    </div>
                  </td>
                </tr>
                </template>
              </tbody>
              </template>
            </v-simple-table>
          </div>
        </div>
        <div v-else-if="loading" class="pa-6 text-center title grey--text">Please wait as we load your data</div>
        <div v-else-if="!loading && previousData.length>0" class="pa-6 text-center title grey--text">No Data</div>
      </div>
      
    </div>
    <lb-dialog v-model="approveRUDataDialogue" heading='RU Approval data' :loading="approveRUDataloading" width='800'>
      <template v-slot:body>
        <div class="grey--text font-weight-bold">Local Approval Reason</div>
        <div class="ml-2" v-if="approveRUData.local_ru_approve_comment">
          {{ approveRUData.local_ru_approve_comment }}</div>
        <div class="grey--text" v-else>No Data</div>
        <br>
        <div class="grey--text font-weight-bold">Regional Approval Reason</div>
        <div class="ml-2" v-if="approveRUData.regional_ru_approve_comment">
          {{ approveRUData.regional_ru_approve_comment }}</div>
        <div class="grey--text" v-else>No Data</div>
      </template>
    </lb-dialog>
  </div>
</template>

<script>
export default {
  name: 'ru_list_after_filter',
  data: function(){
    return {
      userid: '',
      page: 1,
      pagecount: 1,
      perpage: 50,
      previousData:this.data || [],
      selectedlocalapproval: [],
      selectedregionalapproval: [],
      dataLoading:false,
      isRequestinPendingState:false,
      filterData:[],
      container:{},
      approveRUData:{},
      approveRUDataloading:false,
      approveRUDataDialogue:false,
      activitylogconfigcycle: {
        cycleopen: { icon: "mdi-circle-outline", color: "grey" },
        cycleclose: { icon: "mdi-circle", color: "grey" }
      },
      selectedHeader:"rucode",
      seachFilter: "",
      selectedFilterItems:[],
      appname:"",
      reportingunit_list : [],
      tab:'tab-1',
      filterValue:[],
      isfilteredData:false,
      tableHeight:''
    }
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "",
    },
    view: {
      type: Number,
      default: 0,
    },
    enablecheckboxlocal: {
      type: Boolean,
      default: false,
    },
    enablecheckboxregional: {
      type: Boolean,
      default: false,
    },
    loading:{
      type: Boolean,
      default: false,
    },
    cycletype:{
      type:String,
      default:'Actual'
    },
    isDataRefreshed:{
      type:Boolean,
      default:false
    }
  },
  activated() {
    this.refreshData();
  },
  mounted() {
    this.appname = "m_" + (this.$route.path || "").split("/")[1] || "";
    this.refreshData();
  },
  computed:{
    filtereddata() {
      return this.previousData;
    },
    
  },
  created(){
    this.tableHeight = window.innerHeight-213 ;    
  },
  methods: {
    getFilterRUList(key = "", search = "") {
      console.log(key)
      const filterItemsCopy = JSON.parse(JSON.stringify(this.reportingunit_list))
      if (search) {
        var list = filterItemsCopy.filter((x) => {
          if (typeof this.selectedFilterItems !== 'undefined') {
            if ((x.code || "").toLowerCase().includes((search || "").toLowerCase())) {
              return x
            }
          } else {
            if (
              (x.code || "").toLowerCase().includes((search || "").toLowerCase())
            )
              return x || [];
          }
        });
        for (const i of this.selectedFilterItems || []) {
          const index = list.findIndex(item => item.value === i);
          if (index !== -1) {
            list.splice(index, 1);
          }
        }
        return list
      } else {
        if (typeof this.selectedFilterItems !== 'undefined') {
          for (const i of this.selectedFilterItems || []) {
            filterItemsCopy.filter(item => item.code !== i);
          }
          return filterItemsCopy
        } else {
          return this.reportingunit_list || [];
        }
      }
    },
    resetPage(){
      this.page = 1;
    },
    refreshData() {
      this.resetPage();
      this.selectedlocalapproval = [];
      this.selectedregionalapproval = [];
      this.userid = this.$store.state.user.id;
      this.pagecount = Math.ceil(this.previousData.length / this.perpage);
      this.reportingunit_list = (this.$store.state[this.appname] || {}).actualhierarchydata?.reportingunitlist || []
    },
    sendEvent(type, value){
      if(this.type === 'Reporting Unit'){
        this.$emit(type, {reportingunit: value._id, cycle: value.cycle, previousLoadedRUList:this.previousData});
      }
      else this.$emit(type, value._id);
    },
    handleScroll(event) {
      this.container = event.target;
      if (this.container.scrollHeight - this.container.scrollTop <= this.container.clientHeight+500 && !this.isRequestinPendingState && this.type === 'Reporting Unit' && this.data.length>=50 && !this.isfilteredData) {
        this.dataLoading=true
        this.isRequestinPendingState=true
        this.$emit('nextPage',this.pagination_page++)
        this.$emit('getreportingunit',this.loadReportingUnit)
      } else if (this.container.scrollHeight - this.container.scrollTop <= this.container.clientHeight + 500 && !this.isRequestinPendingState && this.type === 'Reporting Unit' && this.data.length < 50 && !this.isfilteredData){
        this.dataLoading = true
        this.isRequestinPendingState = true
      }
    },
    getPercentageProgress(n = 0, d = 0){
      if(n === 0 && d === 0) return 1;
      else return n/d;
    },
    getApproveRUData(ruid){
      this.approveRUDataDialogue = true;
      this.approveRUDataloading = true;
      this.approveRUData = {};
      let url = "/v2/varianceanalysis/analysis/getrulevelapprovaldata/"+ruid;
      this.axios.post(url).then(dt => {
        if(dt.data.status === "success") {
          this.approveRUData = dt.data?.data[0] || {};
        }
        else throw new Error (dt.data.message || "Error loading download list");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.approveRUDataloading = false;
      });
    },
    setHeader(d){
        this.selectedHeader = d;
    },
    removeSelectedFilterItem(item){
      //this.getaccessRUlist();
      this.reportingunit_list = [...[item], ...this.reportingunit_list];
      if(this.selectedFilterItems.length === 0){
        this.previousData = this.data || [];
        this.isfilteredData = false;
      }
    },
    removeFilteredItems(){
      this.reportingunit_list = [...this.selectedFilterItems, ...this.reportingunit_list];
      this.selectedFilterItems =[];
      this.previousData = this.data || [];
      this.isfilteredData = false;
    },

    async getaccessFilterRUlist(){
      let selectedRUids = []
      this.selectedFilterItems.forEach((key)=>{
        selectedRUids.push(key._id);
      })
      if(selectedRUids.length>0){
        this.previousData = [];
        this.dataLoading = true
      }
      for(let i=0;i<=(selectedRUids.length/10);i++ ){
        let response = await this.getFilteredListData(selectedRUids.slice((i) * 10, (i+1) * 10));
        this.previousData = [...this.previousData, ...response]
      }
      this.dataLoading = false
    },

    async getFilteredListData(rulist){
      let filters = {}
      if(this.cycletype === 'Forecast'){
        filters = ((this.$store.state[this.appname] || [])).forecastfilter;
      }
      else{
        filters = ((this.$store.state[this.appname] || [])).actualfilter;
      }
      Object.keys(filters).forEach((key) => {
        filters[key] = filters[key] || "";
      });
      this.loading = true;
      return this.axios.post("/v2/varianceanalysis/analysis/getreportingunit_pagination", {filter: { cycletype: this.cycletype, year: filters.selectedyear, role: filters.selectedrole, network: filters.selectednetwork, region: filters.selectedregion, country: filters.selectedcountry, period: filters.selectedperiod, reportingunits: rulist, limit:10 }}).then(dt => {
        if (dt.data.status === "success") {
          // this.previousData = dt.data.data  || [];
          this.isfilteredData = true;
          return (dt.data?.data || []);
        }
        else throw new Error(dt.data.message || "Error fetching data");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
        this.isRequestPending = false;
      })
    },
    
    getFilterRUs(){
      let filters = {}
      if(this.cycletype === 'Forecast'){
        filters = ((this.$store.state[this.appname] || [])).forecastfilter;
      }
      else{
        filters = ((this.$store.state[this.appname] || [])).actualfilter;
      }
      Object.keys(filters).forEach((key) => {
        filters[key] = filters[key] || "all";
      });
      let selectedRUids = []
      this.selectedFilterItems.forEach((key)=>{
        selectedRUids.push(key._id);
      })
      this.reportingunit_list  =  (this.$store.state[this.appname] || {}).actualhierarchydata?.reportingunitlist || []
      this.reportingunit_list = this.reportingunit_list.filter((item)=> (filters.selectednetwork === 'all' || item.network === filters.selectednetwork) && (filters.selectedregion === 'all' || item.region === filters.selectedregion) && (filters.selectedcountry === 'all' || item.country === filters.selectedcountry) && selectedRUids.indexOf(item._id) === -1)
      
      if(filters.selectedrole === 'approver'){
        this.reportingunit_list = this.reportingunit_list.filter((item)=> item.approver?.indexOf(this.userid)!== -1)
      }else if(filters.selectedrole === "submitter"){
        this.reportingunit_list = this.reportingunit_list.filter((item)=> item.submitter?.indexOf(this.userid)>-1)
      }else if(filters.selectedrole === "regional_approver"){
        this.reportingunit_list = this.reportingunit_list.filter((item)=> item.regional_approver?.indexOf(this.userid)!== -1)
      }
      // this.reportingunit_list = this.reportingunit_list.filter(obj => new Date(obj.created_at).getFullYear() === filters.selectedyear);
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  watch: {
    data() {
      if(this.data.length >0 && this.isRequestinPendingState && (this.view===0 || this.view===1 || this.view === 2) && this.previousData.length !== this.data.length){
        this.previousData = []
        this.previousData = this.data
        this.isRequestinPendingState=false
        this.dataLoading=false;
      }
      else if (!this.isRequestinPendingState && (this.view === 0 || this.view === 1 || this.view === 2)){
        this.previousData= []
        this.previousData= this.data
        this.isRequestinPendingState=false
        this.dataLoading=false;
      }
      else{
        this.dataLoading=false;
      }
    },
    isDataRefreshed(){
      if(this.isDataRefreshed === true){
        this.selectedlocalapproval = [];
        this.selectedregionalapproval = [];
        this.previousData = []
      }
    },
    selectedlocalapproval() {
      this.$emit('approvelocal', this.selectedlocalapproval);
    },
    selectedregionalapproval() {
      this.$emit('approveregional', this.selectedregionalapproval);
    },
    filterValue: {
      handler(newVal) {
        if (this.selectedHeader !== "") {
          let matchedItem = [this.reportingunit_list.find(item=> item?._id === newVal[newVal.length-1])] || []
          this.reportingunit_list = this.reportingunit_list.filter(item => item?._id !== newVal[newVal.length - 1] );
          if(matchedItem.length >0 && typeof matchedItem[0] !== undefined){
            this.selectedFilterItems = [...this.selectedFilterItems, ...matchedItem]
          }
        }
      },
      deep: true
    },
    loading(){
      if(this.loading === true){
        this.previousData = []
      }
    }
  }
}
</script>

<style>
.scrollable-table-container {
  max-height: 77vh;
  /* Adjust the maximum height as needed */
  overflow-y: auto;
  /* Enable vertical scrolling */
}
.scrollable-card {
  max-height: 300px; /* Set maximum height for scrollable card */
  overflow-y: auto; /* Enable vertical scrollbar */
}
.spinner-container {
  display: flex;
  justify-content: center; /* Horizontal centering */
  align-items: center; /* Vertical centering */
  height: 100%; /* Ensure it takes the full height of the parent */
}

.scroll {
  overflow-x: auto;
  max-height: 250px;
  width: 280px;
}

.single-line-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>